<template>
  <section>
    <h1>{{title}}</h1>
    <ul>
      <li v-for="(breadcrumb,index) in breadcrumbs" :key="index">
        <router-link :to="breadcrumb.slug" v-if="breadcrumb.slug">{{breadcrumb.title}}</router-link>
        <span v-if="breadcrumb.slug == undefined">{{breadcrumb.title}}</span>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "TitleBreadCrumb",
  props: ["title", "breadcrumbs"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
section {
  background: $midlightgray;
  padding: 20px;
}
h1 {
  // margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
}
ul {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
  li {
    margin-right: 30px;
    font-size: 13px;
    position: relative;
    &::after {
      content: "\f101";
      position: absolute;
      right: -20px;
      font-family: "Fontawesome";
    }
    &:last-of-type {
      &::after {
        display: none;
      }
    }
    a{
        text-decoration: none;
        color:$dark;
    }
  }
}
</style>